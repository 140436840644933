import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Zollstock: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Zollstock,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Zollstock"
          d="M467.3,741.7L482,710l10,16l14,6l14,58h-28l-4,42l10-2v14l-24,18l-42.1-24.9c1.1-2.9,3.5-7.4,4.7-10.3 c-0.5-0.5-5.9-4.3-6.6-4.8l0,0L467.3,741.7z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 461.1859 779.5323)"
        >
          Zollstock
        </text>
      </g>
    </>
  );
});
